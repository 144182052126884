import Head from 'next/head';

export default function Metatags({
  title = 'Shukream | あなたの就活Dream',
  description = 'Shukream（シュクリーム）は就職活動に必要な情報共有コミュニティです。',
  image = 'https://res.cloudinary.com/shukream/image/upload/v1620507359/landing-illust_hqn7j3.png',
}) {
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="favicon.ico" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@shukreamjp" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  );
}
